import { useState } from "react";
import { errorHandler } from "../../helpers/errorHandler";
import { ReactComponent as NextIcon } from "../../assets/images/svg/right-arrow-icon.svg";
import { ReactComponent as EditIcon } from "../../assets/images/svg/edit-icon.svg";
import Countdown, { zeroPad } from "react-countdown";
import { hideWord } from "../../helpers/hideWord";
import { Loader } from "../../component/Loader/Loader.component";
import { toast } from "react-toastify";
import { apiEndpoints } from "../../apis/apiEndpoints";
import CONFIG from "../../helpers/config";
import { patchData, postData } from "../../apis/apiMethods";
import { getFromLocalStorage } from "../../helpers/localStorage";
import { LOCAL_STORAGE_KEYS } from "../../helpers/localStorageKeys";
import OtpInput from "react-otp-input";

interface ISignUpStepTwo {
  setEdit: (newState: boolean) => void;
  setStep: (newState: number) => void;
}

const SignUpStepTwo: React.FC<ISignUpStepTwo> = ({ setEdit, setStep }) => {
  const [loading, setLoading] = useState(false);
  const [pin, setPin] = useState("");
  const [counter, setCounter] = useState(Date.now() + 60000);

  let phoneNumber = getFromLocalStorage(LOCAL_STORAGE_KEYS.PHONENUMBER);
  let updateCheck = localStorage.getItem(LOCAL_STORAGE_KEYS.UPDATE_NUMBER);
  let email = getFromLocalStorage(LOCAL_STORAGE_KEYS.EMAIL)

  const onSubmit = async () => {
    setLoading(true);
    const reqBody = {
      user_id: getFromLocalStorage(LOCAL_STORAGE_KEYS.USERID),
      otp: pin,
    };

    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const res: any = await postData(
        `${CONFIG.BASE_URL2}${apiEndpoints.VALIDATE_OTP}`,
        reqBody
      );
      setStep(3);
    } catch (error) {
      setLoading(false);
      toast.error(errorHandler(error));
    }
  };
  const onSubmitUpdatedPhoneNumber = async () => {
    setLoading(true);

    const reqBodyForUpdatedNumber = {
      user_id: getFromLocalStorage(LOCAL_STORAGE_KEYS.USERID),
      otp: pin,
      phone_number: phoneNumber,
    };

    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const res: any = await patchData(
        `${CONFIG.BASE_URL2}${apiEndpoints.VALIDATE_UPDATED_NUMBER_OTP}`,
        reqBodyForUpdatedNumber
      );
      setStep(3);
    } catch (error) {
      setLoading(false);
      toast.error(errorHandler(error));
    }
  };

  const resendOTP = async () => {
    setLoading(true);
    const reqBody = {
      user_id: getFromLocalStorage(LOCAL_STORAGE_KEYS.USERID),
      email_or_phone_number: phoneNumber,
    };

    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const res: any = await postData(
        `${CONFIG.BASE_URL2}${apiEndpoints.RESEND_OTP}`,
        reqBody
      );
      setCounter(Date.now() + 60000);
    } catch (error) {
      setLoading(false);
      toast.error(errorHandler(error));
    }
    setLoading(false);
  };

  const resendOTPPhoneNumber = async () => {
    setLoading(true);

    const reqBody = {
      phone_number: phoneNumber,
      user_id: getFromLocalStorage(LOCAL_STORAGE_KEYS.USERID),
    };

    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const res: any = await patchData(
        `${CONFIG.BASE_URL2}${apiEndpoints.UPDATE_USER_PHONE_NUMBER}`,
        reqBody
      );
      setCounter(Date.now() + 60000);
    } catch (error) {
      toast.error(errorHandler(error));
    }
    setLoading(false);
  };

  return (
    <>
      <div className="mb-7 pt-6 lg:pt-[96px]">
        <h3 className="text-[26px] md:text-[32px] lg:leading-[38px] font-[300] pb-2">
          Phone number verification
        </h3>
        <p className="text-aellaGray text-[14px] md:text-[16px] font-[200] lg:leading-[20px]">
          Your verification code was sent to
          <span className="text-aellaBlue pl-1">
              {email}
          </span>
        </p>
      </div>
      <div className="border border-[#C5D4F6] w-[286px] rounded-[30px] bg-[#F3F5FC] py-2 px-[15px] text-[14px] lg:leading-[14px] font-[250] flex items-center mb-12">
        Entered a wrong phone number?
        <span
          className="flex items-center text-aellaBlue pl-2 cursor-pointer"
          onClick={() => {
            setEdit(true);
          }}
        >
          Edit.
          <span className="pl-2">
            <EditIcon />
          </span>
        </span>
      </div>

      <OtpInput
        numInputs={6}
        placeholder="000000"
        onChange={(otp: string) => setPin(otp)}
        isInputNum={true}
        value={pin}
        inputStyle={{
          borderBottom: "1px solid #2054D2",
          marginRight: "13px",
          width: "50px",
          height: "50px",
          paddingLeft: "0px",
        }}
      />

      <div className="py-[39px]">
        <Countdown
          date={counter}
          key={counter}
          renderer={({ minutes, seconds, completed }) => {
            if (completed) {
              return (
                <button
                  className="text-aellaBlue disabled:opacity-75 text-[16px] lg:leading-[19px] font-[300] cursor-pointer"
                  onClick={() =>
                    updateCheck === "true"
                      ? resendOTPPhoneNumber()
                      : resendOTP()
                  }
                  disabled={loading}
                >
                  Resend Code
                </button>
              );
            } else {
              return (
                <span className="text-[16px] lg:leading-[19px] font-[300] text-aellaGray ">
                  Resend code in
                  <span className="text-aellaBlue ">
                    {" "}
                    {zeroPad(minutes)}:{zeroPad(seconds)}
                  </span>
                </span>
              );
            }
          }}
        />
      </div>

      <button
        type="submit"
        className="bg-aellaBlue disabled:opacity-75 rounded text-white py-4 px-6 items-center w-full md:w-1/2 flex justify-center  cursor-pointer"
        disabled={loading || pin?.length !== 6}
        onClick={() =>
          updateCheck === "true" ? onSubmitUpdatedPhoneNumber() : onSubmit()
        }
      >
        Continue
        <span className="pl-2">
          <NextIcon />{" "}
        </span>
        {loading && <Loader />}
      </button>
    </>
  );
};

export default SignUpStepTwo;
