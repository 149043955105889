import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

import { ModalContainer } from "../../../modal/ModalContainer";
import { ReactComponent as RemoveIcon } from "../../../assets/images/svg/remove-from-list.svg";
import { ReactComponent as CloseIcon } from "../../../assets/images/svg/white-close-modal.svg";
import CustomTextArea from "../../../component/CustomHTMLElements/CustomTextArea";
import BlueButton from "../../../component/Button/BlueButton";
import { approveLoansFn, declineLoansFn } from "../queries";
import { errorHandler } from "../../../helpers/errorHandler";
import { Employee } from "../../../interfaces/employee-loans";

export const ApproveOrDeclineLoanRequestModal = ({
  showModal,
  closeModal,
  setSelectedEmployees,
  selectedEmployees,
  status,
  setInvalidList,
  isSingle = false,
}: {
  showModal: boolean;
  closeModal: () => void;
  setSelectedEmployees: (state: any) => void;
  selectedEmployees: any;
  status: string;
  setInvalidList?: (state: Employee | null) => void;
  isSingle?: boolean;
}) => {
  const [reason, setReason] = useState("");
  const [error, setError] = useState("");
  const queryClient = useQueryClient();
  const handleClose = () => {
    closeModal();
    setSelectedEmployees([]);
    setReason("");
  };

  const { isLoading: isApproving, mutateAsync: approveLoanAsync } = useMutation(
    {
      mutationFn: approveLoansFn,
      onSuccess: (data) => {
        toast.success(data?.message);
        queryClient.invalidateQueries("pending requests");
        queryClient.invalidateQueries("employer loan details");
        handleClose();
      },
      onError: () => {
        toast.error(errorHandler(error));
      },
    }
  );
  const { isLoading: isDeclining, mutateAsync: declineLoanAsync } = useMutation(
    {
      mutationFn: declineLoansFn,
      onSuccess: (data) => {
        toast.success(data?.message);
        queryClient.invalidateQueries("pending requests");
        queryClient.invalidateQueries("approved loans");
        queryClient.invalidateQueries("employer loan details");
        handleClose();
      },
      onError: () => {
        toast.error(errorHandler(error));
      },
    }
  );
  const handleApproval = async () => {
    const loanIds = selectedEmployees?.map((employee) => employee.id);
    try {
      await approveLoanAsync({
        loan_ids: loanIds,
      });
      setInvalidList(loanIds);
    } catch (error) {}
  };

  const handleDecline = async () => {
    if (!reason) return setError("Reason is required");
    try {
      await declineLoanAsync({
        decline_reason: reason,
        loan_ids: selectedEmployees?.map((employee) => employee.id),
      });
    } catch (error) {}
  };

  useEffect(() => {
    if (reason?.length) setError("");
  }, [reason]);

  const handleRemoval = (id: string) => {
    const newList = selectedEmployees?.filter((each) => each?.id !== id);
    setSelectedEmployees(newList);
  };

  useEffect(() => {
    if (!selectedEmployees?.length) {
      closeModal();
    }
  }, [selectedEmployees]);

  return (
    <ModalContainer showModal={showModal} closeModal={closeModal}>
      <div
        className={`w-full lg:w-[35vw] relative right-0 h-auto max-h-[90vh] z-50 flex flex-col overflow-y-auto justify-center pt-5 md:pt-10 rounded-[8px] bg-white fast_animation ${
          showModal
            ? "mt-0 opacity-100 visible"
            : "mt-[5rem] opacity-0 invisible"
        }`}
      >
        <button
          onClick={closeModal}
          className="absolute -top-8 -right-0 cursor-pointer"
        >
          <CloseIcon />
        </button>
        <div className="px-5 md:px-10">
          <p className="text-[24px] text-[#232323] text-left">
            {status} Loan To {!!isSingle ? "This" : "These"} User
            {!isSingle && "s"}
          </p>

          <p className="text-[#7b7b7b] mt-4 font-light text-left">
            Are you sure you want to {status?.toLowerCase()} the loan request
            for {!!isSingle ? "this" : "these"} user{!isSingle && "s"}? Once
            loan is {status?.toLowerCase()}d, this cannot be undone.
          </p>
          {!isSingle && (
            <div className="rounded-[8px] border border-[#EDEFF5] p-[10px] my-[10px] flex flax-wrap items-center gap-3 mb-6">
              {selectedEmployees?.map((each) => {
                return (
                  <div
                    key={each?.id}
                    className="flex items-center gap-x-2 bg-[#FAFAFA] border border-[#EDEFF5] rounded-[24px] px-[10px] py-1"
                  >
                    <p className="text-base font-light text-aellaBlack capitalize">
                      {each?.first_name ?? ""} {each?.last_name ?? ""}
                    </p>
                    <RemoveIcon
                      className="cursor-pointer"
                      onClick={() => handleRemoval(each?.id)}
                    />
                  </div>
                );
              })}
            </div>
          )}

          <div className={isSingle ? "mt-3" : ""}>
            {status === "Decline" && (
              <CustomTextArea
                value={reason}
                onChange={(e) => {
                  setReason(e.target.value);
                  setError("");
                }}
                label="Reason"
                errors={error}
                placeholder="Please provide your reason for this action."
              />
            )}
          </div>
        </div>

        <div className="mt-8 flex justify-end items-center gap-x-4 px-10 py-4 bg-[#ECEDF0] rounded-b-[8px]">
          <button type="button" onClick={closeModal} className="text-[#5B5B5B]">
            Cancel
          </button>
          {status === "Decline" ? (
            <button
              disabled={!selectedEmployees?.length || isDeclining}
              onClick={handleDecline}
              type="button"
              className={`bg-[#FE4149] text-white text-base font-light h-12 px-[10px] rounded-[4px] ${
                !selectedEmployees?.length || isDeclining
                  ? "opacity-50"
                  : "opacity-100"
              }`}
            >
              {isDeclining ? "Please wait.." : "Decline Loan Request"}
            </button>
          ) : (
            <BlueButton
              onClick={handleApproval}
              disabled={!selectedEmployees?.length || isApproving}
              title={isApproving ? "Please wait.." : "Approve Loan Request"}
            />
          )}
        </div>
      </div>
    </ModalContainer>
  );
};
