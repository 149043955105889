import { format } from "date-fns";

import arrowUpIcon from "../../../assets/images/svg/black-arrow-up-icon.svg";
import { formatMoney } from "../../../helpers/formatMoney";
import { Loan } from "../../../interfaces/employee-loans";
import {
  attachClassToLoanStatus,
  attachClassToLoanStatusText,
  attachNameToLoanStatus,
} from "../../../helpers/attachClassNameToTag";
import { EmptyList } from "../EmptyEmployees";
import { useQuery } from "react-query";
import { getEmployeeLoanScheduleFn } from "../queries";
import { LoadingState } from "../LoadingState";
import { ErrorMessage } from "../ErrorMessage";

const ListItem = ({
  status,
  amount,
  dueDate,
}: {
  status: string;
  amount: string | number;
  dueDate: string;
}) => {
  return (
    <div className="bg-[#F3F5FC] flex flex-col sm:flex-row gap-4 justify-between items-start sm:items-center mx-4 px-4 py-4 rounded-[4px]">
      <div className="flex flex-col gap-y-2 text-[#232323]">
        <p
          className={`rounded-[4px] px-[4px] py-[4px] text-[12px] text-center font-light capitalize ${attachClassToLoanStatusText(status)}`}
        >
          {status}
        </p>
        <p className="text-base font-[400]">₦{formatMoney(amount ?? 0)}</p>
      </div>
      <div className="flex flex-col gap-y-2 text-[#232323] sm:text-right text-left">
        <p className="text-[12px] font-light capitalize">due date</p>
        <p className="text-base font-[400]">
          {dueDate ? format(new Date(dueDate), "dd MMM, yyyy") : null}
        </p>
      </div>
    </div>
  );
};

export const RepaymentSchedule = ({ loan }: { loan: Loan }) => {
  const {
    data: repaymentScheduleData,
    isFetching,
    isError,
    refetch
  } = useQuery({
    queryKey: ["repayment schedule", loan?.id],
    queryFn: () =>
      getEmployeeLoanScheduleFn({
        loanId: loan?.id,
      }),
    enabled: !!loan?.id,
  });

  const data = repaymentScheduleData?.data;

  return (
    <div className="flex flex-col border rounded-[6px] border-[#EDF2FF] py-6 mt-8">
      <div className="flex justify-between px-4 pb-7 items-top border-b border-[#EDF2FF]">
        <p className="font-light text-[18px] text[#172B4D] leading-[19px] capitalize">
          repayment schedule
        </p>
        <div className="h-[20px] w-[20px] rotate-180">
          <img src={arrowUpIcon} alt="" />
        </div>
      </div>

      <div className="mt-8 mb-4 flex flex-col gap-y-3">
        {isFetching ? (
          <div className="h-[40vh] flex flex-col gap-y-1 items-center justify-center">
            <LoadingState height="h-auto" />
            <p className="font-[300] text-aellaBlue">Getting Loan Repayment Schedule</p>
          </div>
        ) : isError ? (
          <div className="w-full max-h-[40vh] flex-1 flex justify-center items-center bg-white">
            <ErrorMessage onClick={refetch} />
          </div>
        ) : data?.repayment_schedules?.length === 0 ? (
          <EmptyList
            message="No repayment schedule found"
            className="max-h-[200px]"
          />
        ) : (
          data?.repayment_schedules?.map((schedule, index) => {
            return (
              <ListItem
                key={index}
                amount={schedule?.amount}
                dueDate={schedule?.due_date}
                status={schedule?.status_text}
              />
            );
          })
        )}
      </div>
    </div>
  );
};
