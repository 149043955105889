import { useEffect, useState } from "react";
import Tab from "../../component/Tab/Tab.component";
import { ReactComponent as TransferIcon } from "../../assets/images/svg/transfer-money-icon.svg";
import { ReactComponent as WithdrawIcon } from "../../assets/images/svg/withdraw-money-icon.svg";
import { ReactComponent as AddIcon } from "../../assets/images/svg/white-add-icon.svg";
import WithdrawalAccountDrawer from "../Home/Drawal/WithdrawalAccountDrawer";
import GenerateWithdrawalAccountModals from "../Home/Modal/GenerateWithdrawalAccountModals";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { postData } from "../../apis/apiMethods";
import CONFIG from "../../helpers/config";
import { errorHandler } from "../../helpers/errorHandler";
import { toast } from "react-toastify";
import { DetailObjectType } from "../Home/Home";
import { useMerchantWithdrawalAccountDetails } from "../../hooks/useMerchantWithdrawalAccountDetails.tsx";
import TransferDrawer from "./Drawer/TransferDrawer";
import { useQueryClient } from "react-query";
import { ROUTES } from "../../helpers/routes";
import {
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import { useAuth } from "../../context/auth";
import { useAllMerchantServices } from "../../hooks/useAllMerchantServices";
import { getFromLocalStorage } from "../../helpers/localStorage";
import { LOCAL_STORAGE_KEYS } from "../../helpers/localStorageKeys";

type ContextType = { data: any };

const Wallet = () => {
  const role = getFromLocalStorage(LOCAL_STORAGE_KEYS.ROLE);
  const { details, merchantCntxtStatus } = useAuth();
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isTransferOpen, setIsTransferOpen] = useState(false);
  const [detail, setDetail] = useState<DetailObjectType>({
    account_number: "",
    account_name: "",
    bank_name: "",
    id: "",
    bank_id: "",
  });
  const [withdrawalAmount, setWithdrawalAmount] = useState(null);
  const [withdrawalDescription, setWithdrawalDescription] = useState("");
  const [transferMessage, setTransferMessage] = useState("");
  const [trnxMessage, setTrnxMessage] = useState("");
  const [fromService, setFromService] = useState("");
  const [fromServiceName, setFromServiceName] = useState("");
  const [toService, setToService] = useState("");
  const [toServiceName, setToServiceName] = useState("");
  const [loadingTrnxPin, setLoadingTrnxPin] = useState(false);
  const [modalType, setModalType] = useState("");
  const [transferAmount, setTransferAmount] = useState(0);

  const { data } = useMerchantWithdrawalAccountDetails({
    merchantId: details?.merchant?.id,
  });
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const { data: allMerchantWallets } = useAllMerchantServices({
    merchantId: details?.merchant?.id,
    status: merchantCntxtStatus,
    main: true,
    services: false,
  });
  const onSubmit = async (pin: string) => {
    setLoading(true);
    setLoadingTrnxPin(true);

    const reqBody = {
      naration: withdrawalDescription,
      amount: withdrawalAmount,
      // merchant_id: details?.merchant?.id,
      account_name: detail?.account_name,
      account_number: detail?.account_number,
      bank_id: detail?.bank_id,
      pin,
      source_wallet_id: allMerchantWallets[0].id,
      sender_name: getFromLocalStorage(LOCAL_STORAGE_KEYS.BUSINESSNAME),
    };
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const res: any = await postData(
        `${CONFIG.BASE_URL2}${apiEndpoints.WITHDRAW_FROM_WALLET}`,
        reqBody
      );
      setTrnxMessage(res.message);
      setModalType("step4");
      queryClient.invalidateQueries("merchantWithdrawalAccountDetails");
      queryClient.invalidateQueries("getWalletBalance");
      queryClient.invalidateQueries("getWalletTransactionSummary");
      queryClient.invalidateQueries("getAllMerchantServices");
      queryClient.invalidateQueries("getWalletTransactions");
      queryClient.invalidateQueries("getMerchantServices");
    } catch (error) {
      toast.error(errorHandler(error));
    }
    setLoading(false);
    setLoadingTrnxPin(false);
  };

  const onWalletTransferSubmit = async (pin: string) => {
    setLoadingTrnxPin(true);

    const reqBody = {
      to: toService,
      from: fromService,
      from_name: fromServiceName,
      to_name: toServiceName,
      amount: transferAmount,
      merchant_id: details?.merchant?.id,
      pin,
    };

    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const res: any = await postData(
        `${CONFIG.BASE_URL2}${apiEndpoints.WALLET_TO_WALLET_TRANSFER}`,
        reqBody
      );
      setTransferMessage(res.message);
      setModalType("step6");
      queryClient.invalidateQueries("merchantWithdrawalAccountDetails");
      queryClient.invalidateQueries("getWalletBalance");
      queryClient.invalidateQueries("getWalletTransactionSummary");
      queryClient.invalidateQueries("getAllMerchantServices");
      queryClient.invalidateQueries("getWalletTransactions");
      queryClient.invalidateQueries("getMerchantServices");
      setToService("");
      setFromService("");
      setWithdrawalAmount(null);
    } catch (error) {
      toast.error(errorHandler(error));
    }
    setLoading(false);
    setLoadingTrnxPin(false);
  };

  useEffect(() => {
    if (location.pathname === ROUTES.WALLET) {
      navigate(ROUTES.WALLET_BALANCE); // Redirect to the default tab when the component mounts
    }
  }, [navigate, location]);

  return (
    <>
      <div className="bg-aellaDimGray overflow-auto h-screen">
        <div className=" bg-white px-10 pt-5">
          <div className="border-t border-[#F1F1F1] flex justify-between items-center pt-2 pb-[1px] w-full flex-wrap">
            <div className="flex flex-wrap">
              <Tab text="Balance Overview" path={`${ROUTES.WALLET_BALANCE}`} />

              {/* <Tab text="Transactions" path={`${ROUTES.WALLET_TRANSACTIONS}`} /> */}
              {/* <Tab
                text="Withdrawal Account"
                path={`${ROUTES.WALLET_WITHDRAWAL_ACCOUNT}`}
              /> */}
            </div>
            {location.pathname !==
              `${ROUTES.WALLET}/${ROUTES.WALLET_WITHDRAWAL_ACCOUNT}` &&
              role === "Super Admin" && (
                <div className="flex flex-col lg:flex-row">
                  <button
                    className=" px-[20px] py-[14px] mt-1 lg:mt-0 flex rounded-[6px] bg-aellaBlue text-white  items-center transition ease-in-out duration-300  font-normal text-[14px]"
                    type="submit"
                    onClick={() => setIsTransferOpen(true)}
                  >
                    <span className="mr-2">
                      <TransferIcon />
                    </span>
                    Transfer Money
                  </button>
                  {/* <button
                  className=" px-[20px] py-[14px] mt-1 lg:mt-0 lg:ml-4 flex rounded-[6px] bg-aellaBlue text-white  items-center transition ease-in-out duration-300  font-normal text-[14px]"
                  type="submit"
                  onClick={() => setModalType("step1")}
                >
                  <span className="mr-2">
                    <WithdrawIcon />
                  </span>
                  Withdraw Money
                </button> */}
                </div>
              )}
            {location.pathname ===
              `${ROUTES.WALLET}/${ROUTES.WALLET_WITHDRAWAL_ACCOUNT}` && (
              <button
                className=" px-[20px] py-[14px] mt-1 lg:mt-0 flex rounded-[6px] bg-aellaBlue text-white  items-center transition ease-in-out duration-300  font-normal text-[14px]"
                type="submit"
                onClick={() => setModalType("step3")}
              >
                <span className="mr-2">
                  <AddIcon />
                </span>
                Add Bank Account
              </button>
            )}
          </div>
        </div>
        <div className="">
          {/* {(() => {
            switch (activeTab) {
              case "balance overview":
                localStorage.setItem("activeTab", "balance overview,wallet");
                return <BalanceOverview />;

              case "withdrawal account":
                localStorage.setItem("activeTab", "withdrawal account,wallet");
                return <WalletAccounts data={data} />;
              default:
                return;
            }
          })()} */}
          {/* <Routes>
            <Route
              path={ROUTES.WALLET_BALANCE}
              element={
                // merchantCntxtStatus === 1 || merchantStatus === "1" ? (
                <BalanceOverview />
                // ) : (
                //   <Navigate to={ROUTES.HOME} />
                // )
              }
            />
            <Route
              path={ROUTES.WALLET_WITHDRAWAL_ACCOUNT}
              element={
                merchantCntxtStatus === 1 || merchantStatus === "1" ? (
                  <WalletAccounts data={data} />
                ) : (
                  <Navigate to={ROUTES.HOME} />
                )
              }
            />
          </Routes> */}
          <Outlet context={{ data }} />
        </div>
      </div>
      {modalType && (
        <GenerateWithdrawalAccountModals
          modalType={modalType}
          setModalType={setModalType}
          withdrawalAmount={withdrawalAmount}
          setIsOpen={setIsOpen}
          setDetail={setDetail}
          onSubmitTrnxPin={onSubmit}
          loadingTrnxPin={loadingTrnxPin}
          submitTransferPin={onWalletTransferSubmit}
          toServiceName={toServiceName}
          transferAmount={transferAmount}
          detail={detail}
          transferMessage={transferMessage}
          trnxMessage={trnxMessage}
        />
      )}
      {isOpen && (
        <WithdrawalAccountDrawer
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          detail={detail}
          setModalType={setModalType}
          setWithdrawalAmount={setWithdrawalAmount}
          withdrawalAmount={withdrawalAmount}
          setWithdrawalDescription={setWithdrawalDescription}
          loading={loading}
        />
      )}
      {isTransferOpen && (
        <TransferDrawer
          isOpen={isTransferOpen}
          setIsOpen={setIsTransferOpen}
          setModalType={setModalType}
          setWithdrawalAmount={setTransferAmount}
          withdrawalAmount={transferAmount}
          setWithdrawalDescription={setWithdrawalDescription}
          loading={loading}
          setFromService={setFromService}
          setFromServiceName={setFromServiceName}
          setToService={setToService}
          setToServiceName={setToServiceName}
          toService={toService}
          fromService={fromService}
        />
      )}
    </>
  );
};

export default Wallet;

export function useWithdrawalData() {
  return useOutletContext<ContextType>();
}
