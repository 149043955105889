import React, { useState, Fragment, useEffect } from "react";
import { useLocation } from "react-router-dom";
import SidebarNav from "../Sidebar/SidebarNav.component";
import { Switch } from "@headlessui/react";
import { useEnvironmentData } from "../../hooks/useEnvironmentData";
import { patchData } from "../../apis/apiMethods";
import CONFIG from "../../helpers/config";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { errorHandler } from "../../helpers/errorHandler";
import ProfileSubMenu from "./ProfileSubMenu.component";
import { useAuth } from "../../context/auth";
import { ROUTES } from "../../helpers/routes";
import { EmployeeHeader } from "../../pages/EmployeeLoans.tsx/EmployeeHeader";

interface IDashboardLayout {
  children: React.ReactNode;
}
export const headerHeight = 8;
const DashboardLayout: React.FC<IDashboardLayout> = ({ children }) => {
  const [smallScreenSideBar, setSmallScreenSideBar] = useState(false);
  const [mode, setMode] = useState(false);
  const { pathname } = useLocation();
  const { merchantCntxtStatus } = useAuth();
  const { data: environmentData } = useEnvironmentData({
    status: merchantCntxtStatus,
  });
  const queryClient = useQueryClient();

  useEffect(() => {
    if (environmentData?.environment) {
      setMode(environmentData?.environment);
    }
  }, [environmentData?.environment]);

  const toggleSidebar = () => {
    setSmallScreenSideBar(!smallScreenSideBar);
  };

  const handleMode = async (mode: boolean) => {
    const reqBody = {};

    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const res: any = await patchData(
        `${CONFIG.BASE_URL2}${apiEndpoints.SET_ENVIRONMENT}`,
        reqBody
      );
      queryClient.invalidateQueries("environmentData");
      setMode(mode);
    } catch (error) {
      toast.error(errorHandler(error));
    }
  };

  return (
    <Fragment>
      <main role="main" className="w-full flex h-screen">
        <SidebarNav
          smallScreenSideBar={smallScreenSideBar}
          toggleSidebar={toggleSidebar}
        />
        <div className="flex-1 flex flex-col relative h-screen overflow-y-auto hide_scrollbar">
          <header
            className={`sticky py-[1vh] top-0  left-0  z-[5] px-10 hidden lg:flex bg-white justify-between items-center`}
          >
            <div className="text-[16px] lg:leading-[16px] font-[300]">
              {pathname === "/home" ? (
                "Home"
              ) : pathname === "/sub-accounts" ? (
                "Sub Accounts"
              ) : pathname === "/transactions" ? (
                "Transactions"
              ) : pathname === "/wallet" ? (
                "Wallet Balance Overview"
              ) : pathname?.startsWith(ROUTES.TRANSFERS) ? (
                "Transfers"
              ) : pathname === "/pos" ? (
                "POS"
              ) : pathname === "/event" ? (
                "Event"
              ) : pathname === "/bank-account" ? (
                "Bank Account"
              ) : pathname?.startsWith(ROUTES.EMPLOYEE_LOANS) ? (
                <EmployeeHeader />
              ) : (
                ""
              )}
            </div>

            {/* <div className="flex items-center">
              <p
                className={`${mode ? "text-aellaGray" : "text-[#232323]"} pr-4`}
              >
                Test Mode
              </p>
              <Switch
                checked={mode}
                onChange={() => handleMode(!mode)}
                disabled={merchantCntxtStatus === "0"}
                className={`${
                  mode ? "bg-[#D8A28F]" : "bg-gray-200"
                } relative inline-flex  h-[16px] w-[30px] items-center rounded-full`}
              >
                <span className="sr-only">Mode</span>
                <span
                  className={`${
                    mode ? "translate-x-4" : "translate-x-1"
                  } inline-block h-2.5 w-2.5 transform rounded-full bg-white transition`}
                />
              </Switch>
              <p
                className={`${mode ? "text-[#232323]" : "text-aellaGray"} pl-4`}
              >
                Live Mode
              </p>
            </div> */}
            <ProfileSubMenu />
          </header>
          <div className="lg:hidden h-[8vh] py-[8px] top-0 z-10 px-6 w-full sticky bg-white flex justify-between items-center">
            <div className="flex items-center">
              <div className="rounded-full inline-flex bg-aellaBlue w-[40px] h-[40px] items-center justify-center top-6  cursor-pointer mr-2">
                <svg
                  onClick={() => toggleSidebar()}
                  className="fixed z-30 flex items-center "
                  fill="#ffffff"
                  viewBox="0 0 100 80"
                  width="15"
                  height="11"
                >
                  <rect width="100" height="10"></rect>
                  <rect y="30" width="100" height="10"></rect>
                  <rect y="60" width="100" height="10"></rect>
                </svg>
              </div>
              <p className="text-[16px] lg:leading-[16px] font-[300]">
                {pathname === "/home"
                  ? "Home"
                  : pathname === "/transactions"
                  ? "Transactions"
                  : pathname === "/wallet"
                  ? "Wallet"
                  : pathname?.startsWith(ROUTES.TRANSFERS)
                  ? "Transfers"
                  : pathname === "/pos"
                  ? "POS"
                  : pathname === "/event"
                  ? "Event"
                  : pathname === "/bank-account"
                  ? "Bank Account"
                  : ""}
              </p>
            </div>

            <ProfileSubMenu />
          </div>
          <div className="bg-aellaDimGray flex-1">{children}</div>
        </div>
      </main>
    </Fragment>
  );
};
export default DashboardLayout;
