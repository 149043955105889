import { LegacyRef } from "react";
import { toast } from "react-toastify";
import { useMutation } from "react-query";

import { ReactComponent as ChooseFileIcon } from "../../assets/images/svg/choose-file-icon.svg";
import { ROUTES } from "../../helpers/routes";
import { EmployeesPageState } from "../../interfaces/employee-loans";
import { downloadTemplateFn } from "./queries";
import { errorHandler } from "../../helpers/errorHandler";

export const InitiateUpload = ({
  updateState,
  handleUpload,
  fileRef,
  isUploading,
}: {
  updateState: (state: Partial<EmployeesPageState>) => void;
  handleUpload: (e: any) => void;
  fileRef: LegacyRef<HTMLInputElement> | null;
  isUploading: boolean;
}) => {
  const { mutateAsync, isLoading } = useMutation({
    mutationFn: downloadTemplateFn,
    onSuccess: (data) => {
      window.open(data?.data?.template, "_blank");
    },
    onError: (error) => {
      toast.error(errorHandler(error));
    },
  });

  const downloadTemplate = async () => {
    if (isLoading) return;

    try {
      await mutateAsync();
    } catch (error) {}
  };

  const renderUploader = () => {
    if (isUploading)
      return <p className="text-[#232323]">Uploading, please wait..</p>;

    return (
      <div className="flex">
        <input
          ref={fileRef}
          type="file"
          onChange={(e) => {
            handleUpload(e);
          }}
          className="bg-slate-500 opacity-0  h-[50px] w-40 lg:w-52 absolute top-[54%] right-[35%] md:right-[42%] z-10 cursor-pointer"
          accept=".csv, text/csv, .xlsx, .xls"
        />
        <div className="flex items-center justify-center gap-x-2 h-10 w-32 border border-aellaBlue rounded-[4px] cursor-pointer ">
          <ChooseFileIcon />
          <p className="text-aellaBlue">Choose File</p>
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col w-full">
      <div className="h-[450px] mx-4 sm:mx-10 p-4 bg-white mt-8 rounded-[8px] shadow-sm relative">
        <div className="h-full w-full  border border-aellaBlue border-dashed rounded-[8px] flex flex-col items-center justify-center gap-y-4 text-center px-4">
          <div className="flex">
            <div className="rounded-[50%] flex justify-center items-center text-2xl h-14 w-14 bg-[#C5D4F6] text-aellaBlue">
              KY
            </div>
            <div className="rounded-[50%] -ml-3 border border-white flex justify-center items-center text-2xl h-14 w-14 bg-[#F0D5C7] text-aellaBlue">
              JC
            </div>
            <div className="rounded-[50%] -ml-3 border border-white flex justify-center items-center text-2xl h-14 w-14 bg-[#C5D4F6] text-aellaBlue">
              UF
            </div>
            <div className="rounded-[50%] -ml-3 border border-white flex justify-center items-center text-2xl h-14 w-14 bg-[#F0D5C7] text-aellaBlue">
              CH
            </div>
          </div>

          <p className="text-[#232323] text-2xl">
            Drag and drop your Employees data files here or
          </p>
          {renderUploader()}
        </div>
      </div>

      <div className="mx-10 mb-32 font-light">
        <p className="text-[#232323] text-2xl my-4 font-normal">
          How to upload
        </p>
        <p className=" text-[#5b5b5b]">
          <span className="mr-3"> 1.</span> Download the{" "}
          <strong onClick={() => {}} className="text-aellaBlue cursor-pointer">
            <a onClick={downloadTemplate}>excel template</a>
          </strong>{" "}
          to structure your employee data
        </p>
        <div className="text-[#5b5b5b]">
          <span className="mr-3"> 2.</span>{" "}
          <span className="text-[#5b5b5b]"> Get the</span>{" "}
          <strong
            className="text-aellaBlue cursor-pointer"
            onClick={() => window.open(ROUTES.BANK_CODES, "_blank")}
          >
            Bank Codes here
          </strong>
        </div>
        <p className="text-[#5B5B5B]">
          <span className="mr-3"> 3.</span>
          Add your Data to the template file or compare to see if your data is
          ready to be uploaded
        </p>
        <p className="text-[#5B5B5B]">
          <span className="mr-3 "> 4.</span>
          Upload the file to add your employees into the system.
        </p>
      </div>
    </div>
  );
};
