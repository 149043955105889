import { Link, useSearchParams } from "react-router-dom";
import { ReactComponent as HomeIllustration } from "../../assets/images/svg/home-illustration.svg";
import { ReactComponent as EditIcon } from "../../assets/images/svg/edit-icon.svg";
import { ReactComponent as NextIcon } from "../../assets/images/svg/black-next-arrow-icon.svg";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useWalletBalance } from "../../hooks/useWalletBalance";
import { useWalletTransactionSummary } from "../../hooks/useWalletTransactionSummary";
import { useWalletTransactions } from "../../hooks/useWalletTransactions";
import { ROUTES } from "../../helpers/routes";
import HomeData from "./HomeData";
import { SearchType } from "../../interfaces/home";
import CardSkeletonLoader from "../../component/Skeleton/CardSkeletonLoader.component";
import GenerateWithdrawalAccountModals from "./Modal/GenerateWithdrawalAccountModals";
import WithdrawalAccountDrawer from "./Drawal/WithdrawalAccountDrawer";
import { errorHandler } from "../../helpers/errorHandler";
import CONFIG from "../../helpers/config";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { postData } from "../../apis/apiMethods";
import DateRangeModal from "../../modal/DateRangeModal";
import { useAuth } from "../../context/auth";
import { useMerchantDetails } from "../../hooks/useMerchantDetails";
import { getFromLocalStorage } from "../../helpers/localStorage";
import { LOCAL_STORAGE_KEYS } from "../../helpers/localStorageKeys";
import { useApprovalLog } from "../../hooks/useApprovalLog";
import TrnxDateRangeModal from "../../modal/TrnxDateRangeModal";
import { useAllMerchantServices } from "../../hooks/useAllMerchantServices";

export type DetailObjectType = {
  account_number: string;
  bank_name: string;
  account_name: string;
  id: string;
  bank_id: string;
};

const Home = () => {
  const {
    merchantCntxtStatus,
    details,
    setMerchantCntxtStatus,
    setCredentials,
  } = useAuth();
  const [currentPage] = useState(1);
  const [postsPerPage] = useState(5);
  // const { state } = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [detail, setDetail] = useState<DetailObjectType>({
    account_number: "",
    account_name: "",
    bank_name: "",
    id: "",
    bank_id: "",
  });
  const [loading, setLoading] = useState(false);
  const [withdrawalAmount, setWithdrawalAmount] = useState(0);
  const [withdrawalDescription, setWithdrawalDescription] = useState("");
  const [loadingTrnxPin, setLoadingTrnxPin] = useState(false);
  const [reason, setReason] = useState("");

  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth();

  const [trnxMessage, setTrnxMessage] = useState("");
  const [showOutFlowModal, setShowOutFlowModal] = useState(false);
  const [showTransactionModal, setShowTransactionModal] = useState(false);

  const { data: merchantDetails, isFetching: isLoadingMerchantDetails } =
    useMerchantDetails({
      enabled:
        merchantCntxtStatus === "0" &&
        getFromLocalStorage(LOCAL_STORAGE_KEYS.REFRESH)
          ? true
          : false,
    });

  const { data: log } = useApprovalLog({
    merchantId:
      merchantDetails?.attributes?.profile?.upgrade_status === "Disapproved" ||
      details?.attributes?.profile?.upgrade_status === "Disapproved"
        ? details?.merchant?.id
        : "",
  });

  const [modalType, setModalType] = useState("");

  const [totalVolSearch, setTotalVolSearch] = useState<SearchType>({
    startDate: new Date(year, month, 1),
    endDate: new Date(),
    walletId: "",
    mode: merchantCntxtStatus === "1" ? "merchant" : "",
    merchantId: details?.merchant?.id,
  });

  const [totalValSearch, setTotalValSearch] = useState<SearchType>({
    startDate: new Date(year, month, 1),
    endDate: new Date(),
    walletId: "",
    mode: merchantCntxtStatus === "1" ? "merchant" : "",
    merchantId: details?.merchant?.id,
  });

  const [outFlowSummarySearch, setOutFlowSummarySearch] = useState<SearchType>({
    type: "dr",
    startDate: new Date(year, month, 1),
    endDate: new Date(),
    mode: merchantCntxtStatus === "1" ? "merchant" : "",
    merchantId: details?.merchant?.id,
  });
  const [inFlowSearchForTdy, setInFlowSearchForTdy] = useState<SearchType>({
    type: "cr",
    startDate: new Date(),
    endDate: new Date(),
    mode: merchantCntxtStatus === "1" ? "merchant" : "",
    merchantId: details?.merchant?.id,
  });

  const { data: totalWalletData } = useWalletBalance({
    mode: merchantCntxtStatus === "1" ? "merchant" : "",
    merchantID: merchantCntxtStatus === "1" ? details?.merchant?.id : "",
  });

  const { data: walletInFlowSummaryForTdy } =
    useWalletTransactionSummary(inFlowSearchForTdy);

  const { data: walletOutFlowSummaryData } =
    useWalletTransactionSummary(outFlowSummarySearch);

  const { data: summaryForVolOfTrnx } =
    useWalletTransactionSummary(totalVolSearch);

  const { data: summaryForValOfTrnx } =
    useWalletTransactionSummary(totalValSearch);

  const { data: walletInFlowTransactions, isLoading: walletInFlowTrnxLoading } =
    useWalletTransactions({
      type: "cr",
      mode: merchantCntxtStatus === "1" ? "merchant" : "",
      page: currentPage,
      size: postsPerPage,
      merchantID: details?.merchant?.id,
    });
  const { data: allMerchantWallets } = useAllMerchantServices({
    merchantId: details?.merchant?.id,
    status: merchantCntxtStatus,
    main: true,
    services: false,
  });
  // get the last declined status log i.e the first element having declined status
  useEffect(() => {
    if (log) {
      const lastDeclined = log?.find((data: any) => {
        return data?.status === "Declined";
      });
      setReason(lastDeclined?.comment);
    }
  }, [log]);

  // update the status
  useEffect(() => {
    if (
      merchantDetails &&
      (merchantDetails?.attributes?.profile?.upgrade_status === "Completed" ||
        merchantDetails?.attributes?.profile?.upgrade_status === "Disapproved")
    ) {
      setCredentials({ isAuth: true, merchantDetails });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantDetails]);

  // update the status
  useEffect(() => {
    if (
      (merchantDetails &&
        ((merchantDetails?.merchant &&
          merchantDetails?.merchant?.business_type === "Unregistered" &&
          merchantDetails?.merchant?.unregistered_status === "Approved") ||
          (merchantDetails?.merchant?.business_type === "Registered" &&
            merchantDetails?.merchant?.registered_status === "Approved"))) ||
      (merchantDetails?.attributes?.profile?.upgrade_status === "Approved" &&
        merchantCntxtStatus === "0" &&
        merchantDetails?.merchant?.deactivated_at === null)
    ) {
      setCredentials({ isAuth: true, merchantDetails });
      setMerchantCntxtStatus("1");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantDetails]);

  // // update the status
  useEffect(() => {
    if (
      merchantDetails &&
      merchantDetails?.attributes?.profile?.upgrade_status === "Disapproved" &&
      merchantCntxtStatus === "1"
      //  &&
      // merchantDetails?.merchant?.suspended_at !== null
    ) {
      setCredentials({ isAuth: true, merchantDetails });
      setMerchantCntxtStatus("0");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantDetails]);

  //update the query in case of fast rendering
  useEffect(() => {
    setInFlowSearchForTdy({
      ...inFlowSearchForTdy,
      merchantId: details?.merchant?.id,
      mode: merchantCntxtStatus === "1" ? "merchant" : "",
    });
    setOutFlowSummarySearch({
      ...outFlowSummarySearch,
      merchantId: details?.merchant?.id,
      mode: merchantCntxtStatus === "1" ? "merchant" : "",
    });
    setTotalVolSearch({
      ...totalVolSearch,
      merchantId: details?.merchant?.id,
      mode: merchantCntxtStatus === "1" ? "merchant" : "",
    });
    setTotalValSearch({
      ...totalValSearch,
      merchantId: details?.merchant?.id,
      mode: merchantCntxtStatus === "1" ? "merchant" : "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details.merchant_id]);

  //update the react-query with the url params
  // useEffect(() => {
  //   if (searchParams) {
  //     const currentParams: any = Object.fromEntries([...searchParams]);

  //     if (currentParams?.startDateForTrnx || currentParams?.endDateForTrnx) {
  //       setTotalValSearch({
  //         ...totalValSearch,
  //         startDate: new Date(currentParams?.startDateForTrnx),
  //         endDate: new Date(currentParams?.endDateForTrnx),
  //       });
  //       setTotalVolSearch({
  //         ...totalVolSearch,
  //         startDate: new Date(currentParams?.startDateForTrnx),
  //         endDate: new Date(currentParams?.endDateForTrnx),
  //       });
  //     }
  //     if (currentParams?.volOfTrnxServices) {
  //       setTotalVolSearch({
  //         ...totalVolSearch,
  //         walletId: currentParams?.volOfTrnxServices,
  //       });
  //     }
  //     if (currentParams?.valOfTrnxServices) {
  //       setTotalValSearch({
  //         ...totalValSearch,
  //         walletId: currentParams?.valOfTrnxServices,
  //       });
  //     }
  //     if (
  //       currentParams?.startDateForOutFlow ||
  //       currentParams?.endDateForOutFlow
  //     ) {
  //       setOutFlowSummarySearch({
  //         ...outFlowSummarySearch,
  //         startDate: new Date(currentParams?.startDateForOutFlow),
  //         endDate: new Date(currentParams?.endDateForOutFlow),
  //       });
  //     }
  //   }
  // }, [searchParams, outFlowSummarySearch, totalVolSearch, totalValSearch]);

  //update the react-query with the url params
  // useEffect(() => {
  //   const currentParams: any = Object.fromEntries([...searchParams]);
  //   if (currentParams) {
  //     if (currentParams?.startDateForTrnx || currentParams?.endDateForTrnx) {
  //       setTotalValSearch({
  //         ...totalValSearch,
  //         startDate: new Date(currentParams?.startDateForTrnx),
  //         endDate: new Date(currentParams?.endDateForTrnx),
  //       });
  //       setTotalVolSearch({
  //         ...totalVolSearch,
  //         startDate: new Date(currentParams?.startDateForTrnx),
  //         endDate: new Date(currentParams?.endDateForTrnx),
  //       });
  //     }
  //   }
  // }, [searchParams, totalVolSearch, totalValSearch]);

  // //update the  react-query with the url params
  // useEffect(() => {
  //   const currentParams: any = Object.fromEntries([...searchParams]);
  //   if (currentParams?.volOfTrnxServices) {
  //     setTotalVolSearch({
  //       ...totalVolSearch,
  //       walletId: currentParams?.volOfTrnxServices,
  //     });
  //   }
  // }, [searchParams, totalVolSearch]);

  // update the  react-query with the url params
  // useEffect(() => {
  //   const currentParams: any = Object.fromEntries([...searchParams]);

  //   if (currentParams?.valOfTrnxServices) {
  //     setTotalValSearch({
  //       ...totalValSearch,
  //       walletId: currentParams?.valOfTrnxServices,
  //     });
  //   }
  // }, [searchParams, totalValSearch]);

  //update the url params with the initial query
  // useEffect(() => {
  //   if (merchantCntxtStatus === "1") {
  //     const currentParams: any = Object.fromEntries([...searchParams]);

  //     if (
  //       !currentParams?.startDateForTrnx ||
  //       !currentParams?.endDateForTrnx ||
  //       !currentParams?.startDateForOutFlow ||
  //       !currentParams?.endDateForOutFlow
  //     ) {
  //       setSearchParams({
  //         startDateForOutFlow: moment(searchState?.startDateForOutFlow).format(
  //           "YYYY-MM-DD"
  //         ),
  //         endDateForOutFlow: moment(searchState?.endDateForOutFlow).format(
  //           "YYYY-MM-DD"
  //         ),
  //         startDateForTrnx: moment(searchState?.startDateForTrnx).format(
  //           "YYYY-MM-DD"
  //         ),
  //         endDateForTrnx: moment(searchState?.endDateForTrnx).format(
  //           "YYYY-MM-DD"
  //         ),
  //       });
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [
  //   searchState.startDateForTrnx,
  //   searchState.endDateForTrnx,
  //   searchState?.startDateForOutFlow,
  //   searchState?.endDateForOutFlow,
  //   searchParams,
  //   merchantCntxtStatus,
  // ]);

  // function not needed
  const onWalletTransferSubmit = (pin: string) => {};

  const onSubmit = async (pin: string) => {
    setLoading(true);
    setLoadingTrnxPin(true);
    const reqBody = {
      amount: withdrawalAmount,
      narration: withdrawalDescription,
      account_number: detail?.account_number,
      bank_id: detail?.bank_id,
      account_name: detail?.account_name,
      pin,
      source_wallet_id: allMerchantWallets[0].id,
      sender_name: getFromLocalStorage(LOCAL_STORAGE_KEYS.BUSINESSNAME),
    };
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const res: any = await postData(
        `${CONFIG.BASE_URL2}${apiEndpoints.WITHDRAW_FROM_WALLET}`,
        reqBody
      );
      setTrnxMessage(res.message);
      setModalType("step4");
    } catch (error) {
      toast.error(errorHandler(error));
      setLoading(false);
    }
    setLoading(false);
    setLoadingTrnxPin(false);
  };

  if (
    (walletInFlowTrnxLoading && !details?.merchant?.id) ||
    isLoadingMerchantDetails
  ) {
    return (
      <div className="flex flex-col md:flex-row justify-between px-10 pt-[32px] lg:pt-[120px]">
        <CardSkeletonLoader width={300} height={458} />
        <CardSkeletonLoader width={300} height={458} />
        <CardSkeletonLoader width={300} height={458} />
      </div>
    );
  }

  return (
    <>
      <div className="bg-white">
        {merchantCntxtStatus === "1" && (
          <HomeData
            totalWalletData={totalWalletData}
            walletInFlowSummaryForTdy={walletInFlowSummaryForTdy}
            walletOutFlowSummaryData={walletOutFlowSummaryData}
            walletInFlowTransactions={walletInFlowTransactions}
            isLoading={walletInFlowTrnxLoading}
            totalTransactionSum={summaryForValOfTrnx?.sum}
            totalTransactions={summaryForVolOfTrnx?.count}
            totalVolSearch={totalVolSearch}
            setTotalVolSearch={setTotalVolSearch}
            outFlowSummarySearch={outFlowSummarySearch}
            totalValSearch={totalValSearch}
            setTotalValSearch={setTotalValSearch}
            setShowOutFlowModal={setShowOutFlowModal}
            setShowTransactionModal={setShowTransactionModal}
            setShowWithdrawalModal={setModalType}
            details={details}
          />
        )}
        {merchantCntxtStatus === "0" && (
          <div className="pl-10 pr-8 lg:px-10  bg-aellaDimGray">
            <div className="translate-y-44 flex flex-col items-center">
              <HomeIllustration />
              <h1 className="text-[26px] lg:text-[32px] lg:leading-[38px] tracking-[0.2px] font-[300] py-6">
                {details?.attributes?.profile?.upgrade_status ===
                  "InProgress" ||
                details?.attributes?.profile?.upgrade_status === "Completed"
                  ? "Dashboard loading..."
                  : details?.attributes?.profile?.upgrade_status ===
                    "Disapproved"
                  ? "Your account was disapproved ! "
                  : ""}
              </h1>
              {(merchantDetails?.attributes?.profile?.upgrade_status ===
                "InProgress" &&
                merchantDetails?.isProfileCompleted === false) ||
              (details?.attributes?.profile?.upgrade_status === "InProgress" &&
                details?.isProfileCompleted === false) ||
              !details?.attributes?.profile?.hasOwnProperty("upgrade_status") ||
              merchantDetails?.isProfileCompleted === false ||
              (details?.isTransactionPinSet === false &&
                merchantDetails?.isProfileCompleted === true) ? (
                <Link
                  to={ROUTES.PROFILE_SETUP}
                  state={{ merchantDetails: details }}
                >
                  <div className="border flex flex-row border-[#6D91E9] rounded-[8px] bg-[#C5D4F6] p-4 items-start w-[300px] lg:w-[429px] flex-shrink-0 cursor-pointer">
                    <div className=" w-[16px] h-[16px] pr-4 lg:pr-2">
                      <EditIcon />
                    </div>
                    <div className="flex flex-col px-2">
                      <h1 className="text-[16px] leading-[16px] lg:leading-[14px] font-[300]">
                        Complete Your Account Setup
                      </h1>
                      <p className="text-[13px] lg:leading-[21px] font-[100] pt-2 ">
                        Your account is currently on test mode. Kindly complete
                        your account setup to go live..
                      </p>
                    </div>
                    <div className=" w-[16px] h-[32px]">
                      <NextIcon />
                    </div>
                  </div>
                </Link>
              ) : details?.attributes?.profile?.upgrade_status ===
                "Disapproved" ? (
                <div className="flex flex-col items-center">
                  <h1 className="text-[16px] lg:leading-[24px] tracking-[0.2px] text-aellaGray font-[100] text-center">
                    Your account creation request was declined with the reason
                    <br />'{reason}'
                    <br />
                  </h1>
                  <h1 className="text-[16px] lg:leading-[24px] tracking-[0.2px] text-aellaGray font-[100] text-center">
                    Kindly reach out to{" "}
                    <span className="text-aellaBlue">
                      support@aellacredit.com
                    </span>{" "}
                    for further inquiries.
                  </h1>

                  {/* <button
                      type="submit"
                      className="bg-aellaBlue disabled:opacity-75 rounded w-[225px] mt-6 text-white p-[15.5px] items-center flex justify-center cursor-pointer"
                      disabled={loading}
                      onClick={handleOnClick}
                    >
                      Update Profile
                    </button> */}
                </div>
              ) : details?.merchant?.deactivated_at !== null ? (
                <div className="flex flex-col items-center">
                  <h1 className="text-[16px] lg:leading-[24px] tracking-[0.2px] text-aellaGray font-[100] text-center">
                    Your account was deactivated
                    {/* with the reason
                    <br />'{details?.merchant?.suspension_reason}'
                    <br /> */}
                  </h1>
                  <h1 className="text-[16px] lg:leading-[24px] tracking-[0.2px] text-aellaGray font-[100] text-center">
                    Kindly reach out to{" "}
                    <span className="text-aellaBlue">
                      support@aellacredit.com
                    </span>{" "}
                    for further inquiries.
                  </h1>
                </div>
              ) : (
                <>
                  <h1 className="text-[16px] lg:leading-[19px] tracking-[0.2px] text-aellaGray font-[100] items-center">
                    We are currently setting up your dashboard.
                  </h1>
                  <h1 className="text-[16px] lg:leading-[19px] tracking-[0.2px] text-aellaGray font-[100] items-center">
                    Take a break and check back later.
                  </h1>
                </>
              )}
            </div>
          </div>
        )}
      </div>

      {showOutFlowModal && (
        <DateRangeModal
          startDate={outFlowSummarySearch.startDate}
          setSearchState={setOutFlowSummarySearch}
          searchState={outFlowSummarySearch}
          endDate={outFlowSummarySearch.endDate}
          setCloseModal={setShowOutFlowModal}
          startDateName="startDate"
          endDateName="endDate"
          showModal={showOutFlowModal}
        />
      )}
      {showTransactionModal && (
        <TrnxDateRangeModal
          startDate={totalVolSearch.startDate}
          setTotalVolSearch={setTotalVolSearch}
          setTotalValSearch={setTotalValSearch}
          endDate={totalVolSearch.endDate}
          totalVolSearch={totalVolSearch}
          totalValSearch={totalValSearch}
          setCloseModal={setShowTransactionModal}
          startDateName="startDateForTrnx"
          endDateName="endDateForTrnx"
          showModal={showTransactionModal}
        />
      )}
      {modalType && (
        <GenerateWithdrawalAccountModals
          modalType={modalType}
          setModalType={setModalType}
          setIsOpen={setIsOpen}
          setDetail={setDetail}
          submitTransferPin={onWalletTransferSubmit}
          withdrawalAmount={withdrawalAmount}
          onSubmitTrnxPin={onSubmit}
          loadingTrnxPin={loadingTrnxPin}
          trnxMessage={trnxMessage}
          detail={detail}
        />
      )}
      {isOpen && (
        <WithdrawalAccountDrawer
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          detail={detail}
          setModalType={setModalType}
          setWithdrawalAmount={setWithdrawalAmount}
          withdrawalAmount={withdrawalAmount}
          setWithdrawalDescription={setWithdrawalDescription}
          loading={loading}
        />
      )}
    </>
  );
};
export default Home;
