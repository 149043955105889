import { useState } from "react";
import { useQuery } from "react-query";
import "react-datepicker/dist/react-datepicker.css";

import TextField from "../../../component/CustomHTMLElements/TextField";
import BlueButton, {
  OutlineButton,
} from "../../../component/Button/BlueButton";
import { LoanConfigConfirmationModal } from "../Modal/LoanConfigConfirmationModal";
import { SecondaryLoader } from "../../../component/Loader/SecondaryLoader";
import { formatMoney } from "../../../helpers/formatMoney";
import { handleInputValidation } from "../../../helpers/others";
import { ErrorMessage } from "../ErrorMessage";
import { getLoanConfigurationFn } from "../queries";

const LoanConfiguration = () => {
  const { data, isFetching, isError, refetch } = useQuery({
    queryFn: getLoanConfigurationFn,
    queryKey: ["loan config"],
  });

  const config = data?.data;
  const [showModal, setShowModal] = useState(false);
  const [minAmount, setMinAmount] = useState("");
  const [maxAmount, setMaxAmount] = useState("");
  const [interestRate, setInterestRate] = useState("");

  const isNotValid =
    +interestRate > 100 ||
    +interestRate < 0 ||
    !/^(?!$)\d+(\.\d+)?$/.test(interestRate) ||
    !maxAmount ||
    +maxAmount < 1 ||
    !/^(?!$)\d+(\.\d+)?$/.test(maxAmount) ||
    !minAmount ||
    +minAmount < 1 ||
    !/^(?!$)\d+(\.\d+)?$/.test(minAmount) ||
    +minAmount > +maxAmount;

  const renderPageBody = () => {
    if (isFetching) return <SecondaryLoader count={10} />;

    // if (isError) return <ErrorMessage onClick={refetch} />;

    return (
      <form className="py-4 px-0 mt-6">
        <div className="w-full bg-aellaDimGray p-6 rounded-[4px] flex flex-col lg:flex-row gap-x-10">
          <div className="xs:w-full sm:w-[100%] lg:w-[55%]">
            <div className="text-aellaBlack">
              <TextField
                label="Minimum Loan Amount"
                placeholder="N 0.00"
                className="w-full mb-0"
                inputClassName="border-aellaLightGray"
                isRequired
                value={minAmount}
                onChange={(e) => {
                  if (
                    handleInputValidation(e.target.value)?.numbersWithDecimal
                  ) {
                    setMinAmount(e.target.value);
                  }
                }}
                min="1"
                errors={
                  +minAmount < 1 ||
                  !/^(?!$)\d+(\.\d+)?$/.test(minAmount) ||
                  (minAmount && maxAmount && +minAmount > +maxAmount)
                }
                errorMessage={
                  +minAmount > +maxAmount
                    ? "Minimum loan amount cannot be greater than maximum loan amount"
                    : "Amount is invalid!"
                }
              />
            </div>

            <TextField
              label="Maximum Loan Amount"
              placeholder="N 0.00"
              className="w-full mb-0"
              inputClassName="border-aellaLightGray"
              isRequired
              value={maxAmount}
              onChange={(e) => {
                if (handleInputValidation(e.target.value)?.numbersWithDecimal) {
                  setMaxAmount(e.target.value);
                }
              }}
              min="1"
              errors={
                +maxAmount < 1 ||
                !/^(?!$)\d+(\.\d+)?$/.test(maxAmount) ||
                (minAmount && maxAmount && +minAmount > +maxAmount)
              }
              errorMessage={
                +minAmount > +maxAmount
                  ? "Minimum loan amount cannot be greater than maximum loan amount"
                  : "Amount is invalid!"
              }
            />

            <TextField
              label="Monthly Interest Rate"
              placeholder="0%"
              className="w-full"
              inputClassName="border-aellaLightGray"
              isRequired
              value={interestRate}
              onChange={(e) => {
                if (handleInputValidation(e.target.value)?.numbersWithDecimal) {
                  setInterestRate(e.target.value);
                }
              }}
              max="100"
              min="0"
              errors={
                +interestRate > 100 ||
                +interestRate < 0 ||
                !/^(?!$)\d+(\.\d+)?$/.test(interestRate)
              }
              errorMessage="Monthly interest rate is invalid!"
            />
          </div>

          <div className="flex-1 lg:max-w-[40%] rounded-[8px] bg-[#FFFCF4] border border-[#EED3C5] p-4 sm:py-9 sm:px-8 text-aellaBlack">
            <p className="text-[18px]">Summary</p>
            <p className="font-light text-sm mt-1 opacity-70">
              Your current configuration means your employees can access loans
              with:
            </p>

            <div className="mt-6">
              <p className="text-sm opacity-70 font-light">Minimum Amount:</p>
              <p className="text-[18px] truncate">
                {minAmount ? `N ${formatMoney(minAmount)}` : "-"}
              </p>
            </div>
            <div className="mt-6">
              <p className="text-sm opacity-70 font-light">Maximum Amount:</p>
              <p className="text-[18px] truncate">
                {maxAmount ? `N ${formatMoney(maxAmount)}` : "-"}
              </p>
            </div>
            <div className="mt-6">
              <p className="text-sm opacity-70 font-light">
                Cumulative Interest:
              </p>
              <p className="text-[18px]">
                {interestRate ? `${interestRate}%` : "-"}
              </p>
            </div>
          </div>
        </div>

        <div className="flex gap-x-6 flex-col xs:flex-row sm:flex-row gap-y-4 mt-8">
          <OutlineButton
            title="Reset"
            className="px-[55px] w-full xs:w-auto sm:w-auto justify-center"
            type="reset"
            onClick={() => {
              setMinAmount("");
              setMaxAmount("");
              setInterestRate("");
            }}
          />
          <BlueButton
            disabled={isNotValid}
            onClick={(e) => {
              e.preventDefault();
              setShowModal(true);
            }}
            title="Save"
            className="px-[55px] w-full xs:w-auto sm:w-auto justify-center"
            type="submit"
          />
        </div>
      </form>
    );
  };

  return (
    <div className="bg-aellaDimGray w-full h-full hide_scrollbar">
      <div className="bg-white mx-4 lg:mx-10 mt-8 px-6 py-6 h-auto hide_scrollbar">
        <p className="text-aellaBlack text-[20px] lg:text-[24px]">
          Loan Configuration Settings
        </p>

        <p className="text-[#232323] opacity-90 font-light mt-1 pb-[10px] border-b border-b-[#EDEFF5]">
          Choose the type of loan you want to configure for your employees and
          set parameters for it below.
        </p>

        {renderPageBody()}
      </div>
      <LoanConfigConfirmationModal
        showModal={showModal}
        closeModal={() => setShowModal(false)}
        formField={{ minAmount, maxAmount, interestRate }}
      />
    </div>
  );
};

export default LoanConfiguration;
