import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { useMerchantDetails } from "../../hooks/useMerchantDetails";
import { ModalContainer } from "../../modal/ModalContainer";
import StatusModal from "./StatusModal";
import { ReactComponent as CloseModalIcon } from "../../assets/images/svg/close-icon.svg";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import StepFour from "./StepFour";

const Loader = () => {
  return <div className="w-10 h-10 border-aellaBlue loader__container" />;
};

const UpgradeAccount = () => {
  const {
    data: merchantDetails,
    isLoading,
    isError,
    isFetching,
  } = useMerchantDetails({});
  const [modalType, setModalType] = useState("1");
  const [businessType, setBusinessType] = useState("");
  const [stage, setStage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [isRetrying, setIsRetrying] = useState(false);
  const isDisapproved =
    merchantDetails?.merchant?.registered_status === "Disapproved";

  useEffect(() => {
    if (merchantDetails?.attributes?.registeredDocument?.registration_type) {
      setBusinessType(
        merchantDetails?.attributes?.registeredDocument?.registration_type
      );
    }
  }, [merchantDetails]);

  //hide sidemodal anytime the status modal pops up
  useEffect(() => {
    if (showModal) {
      setModalType("");
    }
  }, [showModal]);

  let hasCompletedUpgradeProcess =
    merchantDetails?.attributes?.hasOwnProperty("registeredAddress") &&
    merchantDetails?.attributes?.profile?.upgrade_status === "Completed" &&
    merchantDetails?.attributes?.profile?.upgrade_stage === "Address";

  let hasStartedUpgrade =
    merchantDetails?.attributes?.hasOwnProperty("registeredAddress") ||
    merchantDetails?.attributes?.hasOwnProperty("registeredDocument");

  //trigger retry button
  const retryUpgrade = () => {
    setIsRetrying(true);
    setModalType("1");
    setShowModal(false);
    setStage(1);
  };

  //if status is completed, and there is registered cocument and registeredAddress, show the status that tells them the admin will approve
  useEffect(() => {
    if (
      (merchantDetails?.attributes?.profile?.upgrade_status === "Completed" &&
        hasStartedUpgrade) ||
      merchantDetails?.merchant?.business_type === "Registered"
    ) {
      setStage(5);
      setShowModal(true);
      setModalType("");
    }
  }, [merchantDetails]);

  // //setstage to 1 by default
  useEffect(() => {
    if (merchantDetails?.merchant?.registered_status === "Disapproved") {
      setShowModal(true);
      return;
    }
    if (merchantDetails) {
      switch (true) {
        case !merchantDetails?.attributes?.profile?.hasOwnProperty(
          "upgrade_stage"
        ):
          return setStage(1);
        case !hasStartedUpgrade:
          return setStage(1);
        case merchantDetails?.attributes?.profile?.upgrade_stage === "Type":
          return setStage(2);
        case merchantDetails?.attributes?.profile?.upgrade_stage === "Owner":
          return setStage(3);
        case merchantDetails?.attributes?.profile?.upgrade_stage === "Detail":
          return setStage(4);
        case merchantDetails?.attributes?.profile?.upgrade_stage === "Address":
          return setShowModal(true);
        default:
          break;
      }
    }
  }, [merchantDetails]);

  const renderPage = () => {
    if (isLoading) {
      return (
        <div className="h-full w-full flex items-center justify-center">
          <Loader />
        </div>
      );
    }

    if (isError) {
      toast.error("Could not fetch details. Please try again");
      return;
    }

    if (
      (hasCompletedUpgradeProcess && !isRetrying) ||
      (isDisapproved && !isRetrying)
    ) {
      return (
        <StatusModal
          showModal={showModal}
          setShowModal={setShowModal}
          setModalType={setModalType}
          merchantDetails={merchantDetails}
          retry={retryUpgrade}
        />
      );
    } else
      return (
        <ModalContainer
          showModal={!!modalType ? true : false}
          closeModal={() => {
            setModalType("");
          }}
        >
          <div
            className={`w-full lg:w-[40vw] absolute top-0 h-screen z-50 text-[#232323] flex flex-col rounded-[8px] bg-white py-10 px-4 lg:px-8 overflow-y-auto animation ${
              modalType ? "right-0" : "-right-[40vw]"
            } `}
          >
            <div className="flex justify-between items-center w-full">
              <p className="text-[#7b7b7b] text-sm font-light">
                Step {stage} of 4
              </p>
              <div onClick={() => setModalType("")} className="cursor-pointer">
                <CloseModalIcon />
              </div>
            </div>

            <div className="w-full min-h-[5px] rounded-[100px] my-4 bg-[#EDEFF5]">
              <div
                className={`min-h-[5px] bg-aellaBlue rounded-[100px] ${
                  stage === 1
                    ? "w-1/4"
                    : stage === 2
                    ? "w-1/2"
                    : stage === 3
                    ? "w-3/4"
                    : "w-full"
                }`}
              ></div>
            </div>

            {isLoading ? (
              <div className="h-full w-full flex items-center justify-center">
                <Loader />
              </div>
            ) : stage === 1 ? (
              <StepOne
                isLoading={isLoading}
                stage={stage}
                setStage={setStage}
                setBusinessType={setBusinessType}
                merchantDetails={merchantDetails}
                isFetching={isFetching}
              />
            ) : stage === 2 ? (
              <StepTwo
                stage={stage}
                setStage={setStage}
                merchantDetails={merchantDetails}
                isRetrying={isRetrying}
                isFetching={isFetching}
              />
            ) : stage === 3 ? (
              <StepThree
                setStage={setStage}
                businessType={businessType}
                merchantDetails={merchantDetails}
                isFetching={isFetching}
              />
            ) : stage === 4 ? (
              <StepFour setStage={setStage} merchantDetails={merchantDetails} isFetching={isFetching} />
            ) : null}
          </div>
        </ModalContainer>
      );
  };

  return (
    <>
      <StatusModal
        showModal={showModal}
        setShowModal={setShowModal}
        setModalType={setModalType}
        merchantDetails={merchantDetails}
        retry={retryUpgrade}
      />
      {renderPage()}
    </>
  );
};

export default UpgradeAccount;
