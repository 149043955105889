import axios from "axios";
import { decrypt } from "../helpers/encryptor";
import { LOCAL_STORAGE_KEYS } from "../helpers/localStorageKeys";
import { ROUTES } from "../helpers/routes";
import { getFromLocalStorage } from "../helpers/localStorage";

export const token = () => {
  const token = localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN);
  if (!token) {
    return null;
  }

  return decrypt(token);
};
export const axiosInstance = axios.create();
axiosInstance.interceptors.request.use(
  function (config: any) {
    config.headers = {
      ...config.headers,
      "Content-Type": config?.headers["Content-Type"] ?? "application/json",
      Authorization: `Bearer ${token()}`,
    };

    // If there is no token, delete if from the header before making a request
    if (!token()) {
      delete config.headers.Authorization;
    }
    // you can also do other modification in config
    return config;
  },
  function (error: any) {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(undefined, async function (error) {
  if (error?.response?.status === 401) {
    window.location.replace(ROUTES.LOGIN);
  }
  return Promise.reject(error);
});

export const getData = async (url: string, addId = true) => {
  const memberId = getFromLocalStorage(LOCAL_STORAGE_KEYS.MEMBER_ID);
  const { data } = await axiosInstance({
    method: "get",
    url,
    headers: {
      "X-Aella-Member-Id": addId ? memberId : undefined,
    },
  });
  return data;
};

export const postData = async (
  url: string,
  reqBody?: any,
  contentType?: string
) => {
  const memberId = getFromLocalStorage(LOCAL_STORAGE_KEYS.MEMBER_ID);

  const { data } = await axiosInstance({
    method: "POST",
    url,
    data: reqBody,
    headers: {
      "X-Aella-Member-Id": memberId,
      "Content-Type": contentType,
    },
  });
  return data;
};

// export const postData2 = async (
//   url: string,
//   reqBody: {},
//   customHeaders: any
// ) => {
//   const { data } = await axiosInstance({
//     method: "POST",
//     url,
//     data: reqBody,
//     headers: customHeaders,
//   });
//   return data;
// };

export const patchData = async (
  url: string,
  reqBody: {},
  customHeaders?: any
) => {
  const memberId = getFromLocalStorage(LOCAL_STORAGE_KEYS.MEMBER_ID);

  const { data } = await axiosInstance({
    method: "PATCH",
    url,
    data: reqBody,
    headers: {
      ...customHeaders,
      "X-Aella-Member-Id": memberId,
    },
  });
  return data;
};

export const putData = async (url: string, reqBody: {}) => {
  const memberId = getFromLocalStorage(LOCAL_STORAGE_KEYS.MEMBER_ID);

  const { data } = await axiosInstance({
    method: "PUT",
    url,
    data: reqBody,
    headers: {
      "X-Aella-Member-Id": memberId,
    },
  });
  return data;
};

export const deleteData = async (url: string, reqBody: {}) => {
  const memberId = getFromLocalStorage(LOCAL_STORAGE_KEYS.MEMBER_ID);

  const { data } = await axiosInstance({
    method: "DELETE",
    url,
    data: reqBody,
    headers: {
      "X-Aella-Member-Id": memberId,
    },
  });
  return data;
};
