import CustomInputField from "../../../../component/CustomHTMLElements/CustomInputField";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { yupValidators } from "../../../../helpers/yupValidators";
import { useForm } from "react-hook-form";
import { UnregisteredStepOneForm } from "../../../../interfaces/profileSetUp";
import { postData, putData } from "../../../../apis/apiMethods";
import CONFIG from "../../../../helpers/config";
import { apiEndpoints } from "../../../../apis/apiEndpoints";
import { errorHandler } from "../../../../helpers/errorHandler";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { Loader } from "../../../../component/Loader/Loader.component";
import { useQueryClient } from "react-query";

const schema = yup.object().shape({
  email: yupValidators.email,
  bvn: yupValidators.bvn,
  firstName: yupValidators.firstName,
  lastName: yupValidators.lastName,
  phoneNumber: yupValidators.phoneNumber,
});

const ProfileStepOne = ({
  merchantDetails,
  isFetching,
}: {
  merchantDetails: any;
  isFetching: boolean;
}) => {
  const [loading, setLoading] = useState(false);

  const queryClient = useQueryClient();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<UnregisteredStepOneForm>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (merchantDetails?.attributes?.unregisteredOwnership) {
      setValue(
        "phoneNumber",
        merchantDetails?.attributes?.unregisteredOwnership?.phone_number?.replace(
          "234",
          "0"
        )
      );
    } else if (merchantDetails?.phone_number?.replace("234", "0")) {
      setValue(
        "phoneNumber",
        merchantDetails?.phone_number?.replace("234", "0")
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantDetails]);

  const onSubmit = async ({
    bvn,
    firstName,
    lastName,
    email,
    phoneNumber,
  }: {
    bvn: number;
    phoneNumber: number;
    lastName: string;
    email: string;
    firstName: string;
  }) => {
    setLoading(true);
    const reqBody = {
      firstName: firstName,
      first_name: lastName,
      last_name: lastName,
      email: email,
      phone_number: phoneNumber,
      bvn: bvn,
    };

    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const res: any = await putData(
        `${CONFIG.BASE_URL2}${apiEndpoints.CREATE_UNREG_PROFILE}`,
        reqBody
      );
      queryClient.invalidateQueries("getMerchantDetails");
    } catch (error) {
      toast.error(errorHandler(error));
    }
    setLoading(false);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex items-center">
          <h3 className="text-[20px] lg:leading-[24px] font-[300] tracking-[0.03px]">
            Personal Details
          </h3>

          <h1 className="ml-1 text-[12px] leading-[24px] text-[#EB5757]">*</h1>
        </div>
        <p className="text-[14px] lg:leading-[19px] font-[100] tracking-[0.03px] text-aellaGray pt-2.5 pb-[26px]">
          This is the details of the owner of this business
        </p>

        <div className="flex flex-col lg:flex-row">
          <div className=" w-full lg:pr-6">
            <CustomInputField
              type="text"
              maxLength={128}
              label="First Name"
              errors={errors.firstName}
              {...register("firstName")}
              placeholder="First Name"
              // readOnly={
              //   merchantDetails?.attributes?.unregisteredOwnership?.first_name
              // }
              defaultValue={
                merchantDetails?.attributes?.unregisteredOwnership
                  ?.first_name || merchantDetails?.first_name
              }
              readOnly
            />
          </div>
          <div className="w-full">
            <CustomInputField
              type="text"
              maxLength={128}
              label="Last Name"
              errors={errors.lastName}
              {...register("lastName")}
              placeholder="Last Name"
              // readOnly={
              //   merchantDetails?.attributes?.unregisteredOwnership?.last_name
              // }
              defaultValue={
                merchantDetails?.attributes?.unregisteredOwnership?.last_name ||
                merchantDetails?.last_name
              }
              readOnly
            />
          </div>
        </div>
        <div className="flex flex-col lg:flex-row">
          <div className=" w-full lg:pr-6">
            <CustomInputField
              type="number"
              maxLength={11}
              label="BVN"
              errors={errors.bvn}
              {...register("bvn")}
              placeholder="BVN"
              readOnly={merchantDetails?.attributes?.unregisteredOwnership?.bvn}
              defaultValue={
                merchantDetails?.attributes?.unregisteredOwnership?.bvn
              }
            />
          </div>
          <div className="w-full">
            <CustomInputField
              type="number"
              maxLength={11}
              placeholder="08100000000"
              label="Phone Number"
              hasIcon="+234"
              errors={errors.phoneNumber}
              showRequiredIcon
              {...register("phoneNumber")}
              // readOnly={
              //   merchantDetails?.attributes?.unregisteredOwnership?.phone_number
              // }
              readOnly
            />
          </div>
        </div>

        <CustomInputField
          type="email"
          maxLength={128}
          label="Email Address"
          errors={errors.email}
          {...register("email")}
          placeholder="Email"
          // readOnly={merchantDetails?.attributes?.unregisteredOwnership?.email}
          defaultValue={
            merchantDetails?.attributes?.unregisteredOwnership?.email ||
            merchantDetails?.email
          }
          readOnly
        />

        <div className="flex justify-between">
          <div></div>
          <div></div>
          <div></div>
          {!merchantDetails?.attributes?.profile?.hasOwnProperty(
            "upgrade_stage"
          ) && (
            <button
              type="submit"
              className="bg-aellaBlue disabled:opacity-75 rounded text-white p-[15.5px] items-center flex xxl:mt-7 mb-28 lg:mb-0 justify-center cursor-pointer"
              disabled={loading || isFetching}
            >
              Save and Continue
              {(loading || isFetching) && <Loader />}
            </button>
          )}
        </div>
      </form>
    </>
  );
};
export default ProfileStepOne;
