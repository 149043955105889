import { toast } from "react-toastify";

import { ReactComponent as Empty } from "../../assets/images/svg/empty.svg";
import { ReactComponent as AddIcon } from "../../assets/images/svg/add-account-icon.svg";
import { EmployeesPageState } from "../../interfaces/employee-loans";
import { useLoansContext } from "./EmployeeLoans";

export const EmptyEmployees = ({
  updateState,
}: {
  updateState: (state: Partial<EmployeesPageState>) => void;
}) => {
  const {
    updateContext,
    isSubscribedToEmployeeLoans,
    isFetchingMerchantServices,
  } = useLoansContext();
  return (
    <div className="w-[369px] h-full flex flex-col items-center justify-center text-center">
      <Empty className="mx-auto" />
      <p className="text-[#232323] mt-8">
        You&apos;ve not added any employee to the system yet
      </p>
      <p className="text-[#5B5B5B] mt-2 font-light">
        Start by uploading your employees to the system and request a loan for
        them.
      </p>

      <button
        disabled={isFetchingMerchantServices}
        onClick={() => {
          if (!isSubscribedToEmployeeLoans) {
            return toast.error(
              "This feature has not been activated. Turn on Employee Loans in your services"
            );
          }
          updateContext({
            showHeader: false,
          });
          updateState({
            stage: "initiate",
          });
        }}
        className="bg-aellaBlue border h-11 py-2 px-7 cursor-pointer text-center text-white rounded-[6px] mt-[32px] flex gap-x-[6px] items-center"
      >
        <AddIcon />
        Add Employees
      </button>
    </div>
  );
};

export const EmptyList = ({
  message,
  className,
}: {
  message: string;
  className?: string;
}) => {
  return (
    <div
      className={`w-full h-[250px] lg:h-[450px] flex flex-col items-center justify-center text-center ${className}`}
    >
      <Empty className="mx-auto" />
      <p className="text-[#232323] mt-8 w-[90%] lg:w-[350px] font-light text-center">
        {message}
      </p>
    </div>
  );
};
