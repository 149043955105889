import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useMutation, useQueryClient } from "react-query";
import { Player } from "@lottiefiles/react-lottie-player";

import BlueButton from "../../../component/Button/BlueButton";
import { ModalContainer } from "../../../modal/ModalContainer";
import { ReactComponent as CloseModalIcon } from "../../../assets/images/svg/close-icon.svg";
import SuccessAnimation from "../../../assets/files/Stars.json";
import { errorHandler } from "../../../helpers/errorHandler";
import { setLoanConfigurationFn } from "../queries";

const Successful = ({ closeModal }) => {
  return (
    <div className="flex flex-col items-center justify-center bg-white">
      <div className="flex items-center justify-center h-auto relative">
        <Player
          autoplay
          controls
          loop
          src={SuccessAnimation}
          style={{
            width: "200px",
            height: "120px",
          }}
        />
        <p className="absolute top-[30%] right-[25%] text-2xl text-aellaBlue">
          Successful
        </p>
      </div>
      <p className="text-[#0B1E4B] text-2xl mt-8">Request Successful!</p>
      <p className="text-[#5B5B5B] text-base mt-4 font-light">
        A decision will be made on your request soon.
      </p>
      <BlueButton onClick={closeModal} title="Got it!" className="mt-10" />
    </div>
  );
};

export const LoanConfigConfirmationModal = ({
  showModal,
  closeModal,
  formField,
}: {
  showModal: boolean;
  closeModal: () => void;
  formField: { minAmount: string; maxAmount: string; interestRate: string };
}) => {
  const [status, setStatus] = useState("");
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!showModal)
      setTimeout(() => {
        setStatus("");
      }, 500);
  }, [showModal]);

  const { isLoading, mutateAsync: updateLoanConfigAsync } = useMutation({
    mutationFn: setLoanConfigurationFn,
    onSuccess: () => {
      setStatus("success");
      queryClient.invalidateQueries("loan config");
    },
    onError: (error) => {
      toast.error(errorHandler(error));
    },
  });

  const handleSubmit = async () => {
    try {
      await updateLoanConfigAsync({
        interestRate: +formField?.interestRate,
        minAmount: +formField?.minAmount,
        maxAmount: +formField?.maxAmount,
      });
    } catch (error) {}
  };

  return (
    <ModalContainer showModal={showModal} closeModal={closeModal}>
      <div
        className={`w-full sm:w-[27rem] relative right-0 h-auto min-h-[30vh] max-h-[90vh] z-50 flex flex-col items-center justify-center text-center p-10 rounded-[8px] bg-white fast_animation ${
          showModal
            ? "mt-0 opacity-100 visible"
            : "mt-[5rem] opacity-0 invisible"
        }`}
      >
        {status === "" ? (
          <>
            <p className="text-aellaBlack text-[24px]">Confirmation Request</p>
            <p className="text-[#5B5B5B] font-light mt-4">
              Are you sure you want to proceed <br /> with this configuration?
            </p>
            <BlueButton
              disabled={isLoading}
              onClick={handleSubmit}
              title={isLoading ? "Please wait.." : "Yes, proceed"}
              className="h-12 mt-8 font-light"
            />
          </>
        ) : (
          <Successful closeModal={closeModal} />
        )}
        <div
          className="absolute -top-8 -right-0 cursor-pointer"
          onClick={closeModal}
        >
          <CloseModalIcon />
        </div>
      </div>
    </ModalContainer>
  );
};
