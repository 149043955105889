const baseUrl1 = process.env.REACT_APP_BASEURL1;
const baseUrl2 = process.env.REACT_APP_BASEURL2;
const baseUrl3 = process.env.REACT_APP_BASEURL3;
const faceRecognitionUrl = process.env.REACT_APP_FACE_DETECTION_BASE_URL;

const CONFIG = {
  BASE_URL1: baseUrl1,
  BASE_URL2: baseUrl2,
  BASE_URL3: baseUrl3,
  FACE_DETECTION_BASE_URL: faceRecognitionUrl,
};

export default CONFIG;
